const { blog1 } = require("./blog1");
const { blog2 } = require("./blog2");
const { blog3 } = require("./blog3");
const { blog4 } = require("./blog4");
const { blog5 } = require("./blog5");
const { blog6 } = require("./blog6");
const { blog7 } = require("./blog7");
const { blog8 } = require("./blog8");

export const blogs = [blog1, blog2, blog3, blog4, blog5, blog6, blog7, blog8];
